<template>
    <a :href="`tel:${number}`" class="call-btn">
      <bootstrap-icon
            icon="phone"
            size="1x"
          />
    </a>
</template>

<script>
export default {
  name: 'CallBtn',
  props: {
    number: { type: String, default: '' },
  },
}
</script>
