<template>
  <div v-if="customer" class="customer-detail">
    <title-bar
      edit
      exportBtn
      :title="`${customer.givenName} ${customer.familyName}`"
      @editPressed="goToEdit(customer.id)"
    />
    <div v-if="customer.address" class="customer-detail__info">
      <div class="address">
        <span>{{ customer.address.streetAddress }}</span>
      </div>
      <div class="floor">
        <span v-if="customer.address.flatNumber"
          >{{ $t('forms.flat') }} {{ customer.address.flatNumber }}</span
        >
        <span v-if="customer.address.floorNumber"
          >{{ customer.address.floorNumber }}. {{ $t('forms.floor') }}</span
        >
      </div>
      <div class="contact">
        <div v-if="customer.telephone" class="contact__number">
          <span class="number">{{ customer.telephone }}</span>
          <call-btn :number="customer.telephone" />
        </div>
        <div v-if="customer.email" class="contact__email">
          <span class="email">{{ customer.email }}</span>
          <email-btn :email="customer.email" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import EmailBtn from "@/components/Buttons/EmailBtn.vue";

import CallBtn from "@/components/Buttons/CallBtn.vue";

import ListUtils from "@/mixins/ListUtils";

export default {
  name: "CustomerDetail",
  components: {
    TitleBar,
    CallBtn,
    EmailBtn,
  },
  mixins: [ListUtils],
  data() {
    return {
      customer: null,
    };
  },
  created() {
    this.loadCustomer();
  },
  methods: {
    loadCustomer() {
      const id = this.$route.params.id;
      this.$Users.getResource({ id }).then((response) => {
        this.customer = response.data;
      });
    },
    goToEdit(id) {
      this.$router.push(`/customers/${id}/edit`);
    },
  },
};
</script>
