<template>
  <a :href="`mailto:${email}`" class="custom-btn email-btn">
    <bootstrap-icon
            icon="envelope"
            size="1x"
          />
  </a>
</template>

<script>
export default {
  props: {
    email: { type: String, default: '' },
  },
}
</script>
